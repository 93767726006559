@import '../../global.scss';

.topbar{
    background-color: $mainColor;
    color: $whiteColor;
    height:70px;
    position: fixed;
    width: 100%;
    top:0;
    z-index: 3;
    transition: all 1s ease;
    .wrapper{
        display: flex;
        padding: 10px 30px;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            align-items: center;
                .logo{
                    font-size: 40px;
                    font-weight: 700;
                    text-decoration: none;
                    color: inherit;
                    display: flex;
                    .logoimg{
                        max-width: 50px;
                        border-radius:50%;
                        max-height:50px;
                        margin-right:8px
                    }
                }
                .itemcontainer{
                    display: flex;
                    align-items: center;
                    margin-left: 30px;
        @include mobile{
          display: none;
        }
                .icon{
                    font-size: 20px;
                    margin-right:5px;

                }
                span{
                    font-size:15px;
                    font-weight: 500;
                }
            }
        }

        .right{
            .hamburger{
                width:32px;
                height: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                overflow: hidden;
                span{
                    width:100%;
                    height: 3px;
                    background-color: $whiteColor;
                    transform-origin: left;
                    transition: all 2s ease
                }
            }

        }
    }
    &.active{
        background-color: $secondaryColor;
        color:$mainColor;
        .right{
            .hamburger{
                
                span{
                    &:first-child{
                        background-color: $mainColor;
                        transform: rotate(45deg)
                    }
                    &:nth-child(2){
                        opacity: 0;
                    }
                    &:last-child{
                        background-color: $mainColor;
                        transform: rotate(-45deg)
                    }
                    
                }
            }

        }
    }
}