$mainColor:#022d3a;
$whiteColor:white;
$secondaryColor:#ecebeb;


$width:768px;

@mixin mobile {
    @media ( max-width: #{$width}) {
        @content
    }
}