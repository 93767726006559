@import "../../global.scss";

.skillsList {
  font-size: 14px;
  margin-right: 50px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;

  @include mobile{
    margin-right: 20px;
  }

  &.active {
    background-color: $mainColor;
    color: white;
  }
}