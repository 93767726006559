@import "../../global.scss";

.contact {
  background-color: #aff4f0;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    img {
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    .section{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
         .dock-item{
            margin: 0 10px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            @include mobile {
                margin: 0 5px;
                padding: 0px;
              }
            img{
                order: 1;
                width: 30px;
                height: 30px;
                fill: #27ae60;
                @include mobile {
                    width: 15px;
                    height: 15px;
                  }
            }
            &:hover{
                background: rgba(0,0,0,.025);
            }
            label{
                font-weight: 700;
                order: 2;
                text-decoration: none;
                color: #000;
                opacity: .75;
                font-size: small;
                margin-top: 15px;
                margin-bottom: 0;
            }
         }
    }
  }
}